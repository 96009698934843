import { useEffect, useState } from 'react'

import { Routes } from '@/constants'
import { useCurrentUserContext } from '@/contexts/CurrentUserContext'
import { RoleNameEnum } from '@/graphql/generated'
import { CustomUserAccount } from '@/types/custom'

type RedirectPaths = { [key: string]: string }

const redirectPaths: RedirectPaths = {
  [RoleNameEnum.Owner]: Routes.CRM,
  [RoleNameEnum.SuccessManager]: Routes.CRM,
  [RoleNameEnum.Learner]: Routes.LEARN,
  [RoleNameEnum.Guest]: Routes.LEARN,
}

type ReturnType = {
  loading: boolean
  hasSingleAccount?: boolean
  accounts?: CustomUserAccount[]
  redirectPaths?: RedirectPaths
}

const useAccounts = (): ReturnType => {
  const userContext = useCurrentUserContext()
  const [hasSingleAccount, setHasSingleAccount] = useState<boolean>()
  const [accounts, setAccounts] = useState<CustomUserAccount[] | undefined>()

  useEffect(() => {
    if (!userContext) return

    setHasSingleAccount(userContext.currentUser?.me?.accounts?.length === 1)
    setAccounts(userContext.currentUser?.me?.accounts)
  }, [userContext])

  return {
    loading: !userContext,
    hasSingleAccount,
    accounts,
    redirectPaths,
  }
}

export default useAccounts
