import { t, Trans } from '@lingui/macro'
import Link from 'next/link'

import { Heading, Logo, Space, Text } from '@/components'
import { ContactDetails } from '@/constants'

const Unauthorized: React.FC<React.PropsWithChildren> = () => {
  return (
    <div className="flex h-screen items-center justify-center text-center">
      <Space y={3}>
        <Logo />

        <Heading level={1} variant={TextStyle => TextStyle.MEDIUM_2XL_NORMAL}>
          {t`You don't have permission to access this page.`}
        </Heading>

        <Text variant={TextStyle => TextStyle.REGULAR_LG_NORMAL} className="text-grey-dark">
          <Trans>
            Please go to{' '}
            <Link className="text-purple-base hover:underline" href="/">
              learn.coleap.com
            </Link>{' '}
            or contact support at{' '}
            <a className="text-purple-base hover:underline" href={`mailto:${ContactDetails.SUPPORT_EMAIL}`}>
              {ContactDetails.SUPPORT_EMAIL}
            </a>
          </Trans>
        </Text>
      </Space>
    </div>
  )
}

export default Unauthorized
