import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { Button, Heading, Space, Text } from '@/components'
import { IconRoute } from '@/components/Icons'
import LearningCommunityList from '@/compositions/LearningPath/LearningCommunities/LearningCommunityList'
import { ContactDetails, Routes, Storage } from '@/constants'
import PrivateRoute from '@/containers/PrivateRoute'
import { RoleNameEnum } from '@/graphql/generated'
import useAccounts from '@/hooks/useAccounts'
import useAuth from '@/hooks/useAuth'

import { NextPageWithLayout } from './_app'

const LearningCommunities: NextPageWithLayout = () => {
  const { loading, hasSingleAccount, accounts, redirectPaths } = useAccounts()
  const [mounted, setMounted] = useState(false)
  const { logOut } = useAuth()
  const { replace } = useRouter()

  useEffect(() => {
    setMounted(false)

    if (!loading) {
      if (hasSingleAccount) {
        const account = accounts?.[0]
        const slug = account?.learningCommunity.slug

        if (slug) {
          localStorage.setItem(Storage.CURRENT_LEARNING_COMMUNITY, slug)

          const roleName = account?.role?.name?.toUpperCase() ?? RoleNameEnum.Learner

          replace(`/${slug}${redirectPaths?.[roleName]}`)

          return
        }
      }
    }

    setMounted(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSingleAccount, accounts])

  if (!mounted) return <div />

  return (
    <div>
      <Space y={5}>
        {accounts?.length ? (
          <LearningCommunityList accounts={accounts} />
        ) : (
          <div className="my-12 mx-auto px-4 text-center md:my-20 md:px-8 lg:w-full xl:w-7/12">
            <div className="space-y-4">
              <IconRoute className="inline-block h-12 w-12 text-purple-base" />
              <Heading level={3} variant={TextStyle => TextStyle.MEDIUM_XL_NORMAL}>
                Currently, you don't have any learning communities.
              </Heading>
              <Text variant={TextStyle => TextStyle.REGULAR_LG_NORMAL} className="text-grey-dark">
                Check your emails for more information.
              </Text>
            </div>

            <Space y={10} />

            <div className="rounded-lg bg-white p-8">
              <Text variant={TextStyle => TextStyle.MEDIUM_BASE_NORMAL}>
                If this is a mistake, please reach out to{' '}
                <a className="text-purple-base hover:underline" href={`mailto:${ContactDetails.SUPPORT_EMAIL}`}>
                  {ContactDetails.SUPPORT_EMAIL}
                </a>
              </Text>

              <Space y={10} />
              <Button type="button" size="sm" variant="primary" onClick={() => logOut(Routes.LOGIN)}>
                Log out
              </Button>
            </div>
          </div>
        )}
      </Space>
    </div>
  )
}

LearningCommunities.getLayout = function getLayout(page: JSX.Element) {
  return <PrivateRoute>{page}</PrivateRoute>
}

export default LearningCommunities
