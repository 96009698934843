import { t } from '@lingui/macro'

import { Card, Heading, Space } from '@/components'
import { CustomUserAccount } from '@/types/custom'

import CohortList from '../LearningCommunityCohortList/LearningCommunityCohortList'

type Props = {
  accounts: CustomUserAccount[]
}

const LearningCommunityList: React.FC<React.PropsWithChildren<Props>> = ({ accounts }) => {
  return (
    <div className="w-full py-8 px-6 text-center md:w-3/4 md:px-0 md:text-left xl:w-1/2">
      <Space y={8}>
        <Heading level={1} variant={TextStyle => TextStyle.MEDIUM_XL_SNUG}>
          {t`Where do you want to go?`}
        </Heading>

        <div>
          {accounts.map(({ role, learningCommunity }) => (
            <div className="mb-5" key={learningCommunity.id}>
              <Card justify="center" spaceless>
                <div className=" border-b border-grey-extra-light py-4 last:border-none">
                  <CohortList viewerRole={role} learningCommunity={learningCommunity} />
                </div>
              </Card>
            </div>
          ))}
        </div>
      </Space>
    </div>
  )
}

export default LearningCommunityList
