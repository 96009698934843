import { t } from '@lingui/macro'
import classNames from 'classnames'
import Link from 'next/link'

import { IconCheckCircle, IconSettings } from '@/components/Icons'
import LCLogo from '@/components/LCLogo/LCLogo'
import { Routes } from '@/constants'
import { LearningCommunity, Role, RoleNameEnum } from '@/graphql/generated'
import useRoutes from '@/hooks/useRoutes'
import { CustomCohort } from '@/types/custom'

type Props = {
  learningCommunity: Partial<LearningCommunity> & {
    cohorts: CustomCohort[]
  }
  viewerRole?: Role | null
  onCohortClick?: () => void
}

export default function CohortList({ learningCommunity, viewerRole, onCohortClick }: Props): JSX.Element {
  const { getCohortHomeRoute, router } = useRoutes()
  const { query } = router
  const isOwner = viewerRole?.name === RoleNameEnum.Owner
  const isManager = viewerRole?.name === RoleNameEnum.SuccessManager
  const canManage = isOwner || isManager

  const cohorts = canManage ? learningCommunity.cohorts : learningCommunity.cohorts.filter(cohort => cohort.viewerRole)

  const classes = {
    getCohortContainerClasses: (isCurrentCohort: boolean) =>
      classNames('text-md flex items-center rounded-md px-4 py-2 grow justify-between hover:bg-grey-bright', {
        'bg-purple-bright text-purple-base font-medium': isCurrentCohort,
        'hover:bg-grey-extra-light': !isCurrentCohort,
      }),
  }

  return (
    <div>
      <div className="flex items-center px-6 pb-2">
        <div className="flex grow items-center space-x-2 text-left font-medium text-base">
          <LCLogo size="md" learningCommunity={learningCommunity} />
          <span>{learningCommunity?.name}</span>
        </div>

        <div className="inline-block text-sm">
          <span className="block whitespace-nowrap rounded-full bg-purple-bright bg-opacity-50 px-2 py-1 pb-1.5 font-medium text-purple-base leading-none">
            {viewerRole?.displayValue}
          </span>
        </div>
      </div>

      {cohorts.map(cohort => {
        const isCurrentCohort = cohort.id === query?.cohortId

        return (
          <Link
            onClick={() => onCohortClick?.()}
            key={cohort.id}
            href={getCohortHomeRoute(`${learningCommunity.slug}`, cohort.id)}
            passHref
            className="text-md flex items-center rounded-md px-2 py-2"
          >
            <div className={classes.getCohortContainerClasses(isCurrentCohort)}>
              <span>{cohort.name}</span>
              {isCurrentCohort ? (
                <div>
                  <IconCheckCircle className="h-5 w-5" />
                </div>
              ) : null}
            </div>
          </Link>
        )
      })}

      {canManage ? (
        <Link
          href={`/${learningCommunity.slug}${Routes.MANAGEMENT}`}
          passHref
          className="text-md mx-2 flex items-center rounded-md px-4 py-2 hover:bg-grey-bright"
        >
          <div className="flex items-center space-x-2">
            <IconSettings className="icon-sm" />
            <div>{t`Manage`}</div>
          </div>
        </Link>
      ) : null}
    </div>
  )
}
