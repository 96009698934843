import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect, useState } from 'react'

import { Spinner } from '@/components'
import { Routes } from '@/constants'
import LayoutOwner from '@/containers/Layouts/Owner'
import { useCurrentUserContext } from '@/contexts/CurrentUserContext'
import { getPathLayout } from '@/helpers/pathSettings'
import useAuth from '@/hooks/useAuth'
import usePermissions from '@/hooks/usePermissions'
import useTracking from '@/hooks/useTracking'

import Unauthorized from './Unauthorized'

type Props = {
  excludeSideMenu?: boolean
  headerComponent?: JSX.Element
}

const PrivateRoute: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  headerComponent,
  excludeSideMenu = false,
}) => {
  const { pathname, events } = useRouter()

  const { isLogged, logOut } = useAuth()
  const userContext = useCurrentUserContext()
  const { restrictedAccess, loading: loadingPermissions } = usePermissions()
  const { setupPageViewTracking } = useTracking()

  const [mounted, setMounted] = useState(false)

  useEffect(setupPageViewTracking, [events, setupPageViewTracking])

  useEffect(() => {
    if (isLogged()) return

    logOut(`${Routes.LOGIN}?redirectUrl=${window.btoa(window.location.pathname)}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLogged()) {
      analytics?.identify(userContext?.currentUser?.me?.id ?? '', {}, { Intercom: { hideDefaultLauncher: true } })
      setMounted(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext, userContext?.currentUser?.me])

  if (userContext?.isLoggingOut) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    )
  }

  if (!loadingPermissions && restrictedAccess) return <Unauthorized />

  const CurrentLayout = getPathLayout(pathname) ?? LayoutOwner

  return (
    <>
      <Script src="https://cdn.srv.whereby.com/embed/v1.js" type="module" strategy="afterInteractive" />
      {mounted ? (
        excludeSideMenu ? (
          <>{children}</>
        ) : (
          <CurrentLayout headerComponent={headerComponent}>{children}</CurrentLayout>
        )
      ) : null}
    </>
  )
}

export default PrivateRoute
